<!--统计报表-实时浓度-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model="filter.val"
            placeholder="关键字搜索"
            @keyup.native.enter="getData"
          >
            <el-select
              v-model="filter.field"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="监测点" value="Name"></el-option>
              <el-option label="地址" value="Addr"></el-option>
              <el-option label="编号" value="MN"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showMN">显示设备编号</el-checkbox>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        @sort-change="handleSort"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" align="center" width="55" />
        <el-table-column
          prop="Name"
          label="监测点"
          show-overflow-tooltip
          width="120"
          header-align="center"
        />
        <el-table-column
          prop="Addr"
          label="安装地址"
          show-overflow-tooltip
          width="140"
          header-align="center"
        />
        <el-table-column
          prop="MN"
          v-if="showMN"
          sortable="custom"
          show-overflow-tooltip
          label="设备编码"
          width="120"
          align="center"
          header-align="center"
        />
        <el-table-column
          :formatter="fanFilterFormatter"
          label="风机状态"
          align="center"
          header-align="center"
        />
        <el-table-column
          :formatter="fanFilterFormatter"
          label="净化器状态"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AcquitAt"
          :formatter="dateFormat"
          sortable
          label="采集时间"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CEmissions"
          label="油烟折算浓度"
          width="80"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EmissionsConc"
          label="1分钟浓度(mg/m³)"
          width="90"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FiveConc"
          label="5分钟浓度(mg/m³)"
          width="90"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="TenConc"
          label="10分钟浓度(mg/m³)"
          width="100"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="HourConc"
          label="1小时浓度(mg/m³)"
          width="90"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CGranule"
          label="颗粒物折算浓度(mg/m³)"
          width="120"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CHydrocarbon"
          label="非甲烷总烃折算浓度(mg/m³)"
          width="130"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="设备状态"
          width="70"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <img height="40" width="40" :src="getImg(scope.row.Status)" />
          </template>
        </el-table-column>
        <!-- <el-table-column label='操作' align='center' header-align='center' width='80'>
           <template slot-scope='scope'>
           <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' />
           <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete' />
           </template>
        </el-table-column> -->
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'size')"
        @current-change="(v) => handlePageChange(v, 'page')"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { dateFormater, underline } from "@/util/index";
import _1 from "@/asset/img/1.png";
import _2 from "@/asset/img/2.png";
import _3 from "@/asset/img/3.png";
import _4 from "@/asset/img/4.png";

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      dlg: {
        visible: false,
        title: "",
      },
      filter: {
        val: null,
        field: "MN",
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
      },
      showMN: false,
    };
  },
  computed: {
    ...mapState(["props", "clientHeight"]),
  },
  created() {
    this.getData();
  },
  methods: {
    hasChange() {
      this.changeState = true;
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true);
    },
    fanFilterFormatter(r) {
      if (r.LinkStatus === 1) {
        return "开";
      }
      return "-";
    },
    handleSort(c) {
      if (c.order) {
        if (c.prop === "DataTime") {
          this.filter.by = "acquit_at";
        } else {
          this.filter.by = underline(c.prop);
        }
        this.filter.mode = c.order.replace(/ending/g, "");
      }
      this.getData();
    },
    getImg(status) {
      switch (status) {
        case "NORMAL":
          return _1;
        case "EXCESS":
          return _2;
        case "DOWN":
          return _3;
        case "OFF":
          return _4;
      }
    },
    getData: function () {
      this.loading = true;
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
      };
      para.Param = {};
      if (this.filter.val) {
        para.Param["Key"] = this.filter.field;
        para.Param["Val"] = this.filter.val;
      }
      this.$post("admin/listMultiDimQuery", para).then((res) => {
        this.datalist = res;
        this.datalist.content = res.content;
        this.loading = false;
      });
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.val = null;
      this.getData();
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      this.getData();
    },
    handleEdit: function (index, row) {
      this.dlg.title = "编辑";
      this.dlg.visible = true;
      this.index = index;
      this.formData = Object.assign({}, row);
    },
    handlerClose: function () {
      this.changeState = false;
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
  },
};
</script>
